
import store from '../../../store'
export default [
    {
        path: '/pbc',
        component: "__base",
        children: [
            {
                // module: "PBC",
                path: "project",
                component: "ProjectList.vue",
                meta: { title: "Project List" },
            },
            {
                // module: "PBC",
                path: "project/view/:id",
                component: "ProjectView.vue",
                meta: { title: "Project Details" },
            },
            {
                // module: "PBC",
                path: "requestee/view/:client/:code",
                component: "ProjectListRequestee.vue",
                meta: { title: "Dashboard", allow: true },
                function: () => { store.commit("setNewMenu", []) },
            },
            {
                // module: "PBC",
                path: "requestee/project/:client/:code/:project",
                component: "ProjectViewRequestee.vue",
                meta: { title: "Request List", allow: true },
                function: () => { store.commit("setNewMenu", []) },

            },
            {
                // module: "PBC",
                path: "*",
                component: "",
            },
        ],
    },
]
  // export default router

